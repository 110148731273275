import { StyledPropsTheme } from '../../types';

// 不要有层次, 横向铺开
export default {
	// 桌面 ****************************************************
	DESK_HTML_HORIZONTAL_MARGIN: 150,
	DESK_HTML_SCROLL_WIDTH: 15,
	DESK_BTN_MIN_WIDTH: 80,
	DESK_CELL_INNER_LABEL_WIDTH: 180,
	ERROR_REMINDER_FADE_OUT_DURATION: '3s',
	DESK_DIALOG_HORIZONTAL_PADDING: 15,
	DESK_PAGE_HEADER_HEIGHT: 150, //54,
	// 桌面 ****************************************************

	// 桌面+移动 ************************************************
	PAGE_HEADER_Z_INDEX: 51,
	PAGE_FOOTER_Z_INDEX: 50,
	MOBILE_HTML_HORIZONTAL_MARGIN: 15,

	// 字体 ****************************************************
	FONT_SIZE_HTML: 100,
	FONT_SIZE_SUPER: 24,
	FONT_SIZE_L4: 22,
	FONT_SIZE_L3: 20,
	FONT_SIZE_L2: 18,
	FONT_SIZE_LARGE: 16,
	FONT_SIZE: 14,
	FONT_SIZE_SMALL: 12,
	FONT_FAMILY:
		// "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
		"'PingFangSC-Regular', 'PingFang SC', sans-serif",
	FONT_WEIGHT: 300,
	FONT_WEIGHT_BOLD_LIGHT: 400,
	FONT_WEIGHT_BOLD: 600,
	FONT_COLOR: '#0A0D2B;',
	FONT_COLOR_LIGHT: '#666',
	FONT_COLOR_LIGHT2: '#999',
	// 字体 ****************************************************

	// 颜色 ****************************************************
	COLOR_BACKGROUND: '#f6f6f6',
	COLOR_DISABLED: '#ddd',
	COLOR_PRIMARY: '#FF5640',
	COLOR_PRIMARY_LIGHT_O75: 'rgba(10, 157, 210, 0.75)',
	COLOR_PRIMARY_LIGHT_010: 'rgba(10, 157, 210, 0.1)',
	COLOR_PRIMARY_LIGHT: '#1bbbf4',
	COLOR_REVERSED: '#fff',
	COLOR_WAIVE: '#999',
	COLOR_WAIVE_LIGHT: '#b3b3b3',
	COLOR_INFO: '#306faf',
	COLOR_DANGER: '#EB0C27', //'#dc3545',
	COLOR_DANGER_LIGHT: '#f52800',
	COLOR_ERROR: '#EB0C27', //,'#dc3545',
	COLOR_SUCCESS: '#01CBE5',// '#009490', // '#28a745',
	COLOR_QUESTION: '#b241d4',
	COLOR_WARN: '#f7ba2a',
	COLOR_PRICE: '#e26227',
	COLOR_DISABLED_BACKGROUND: '#f6f7fb',
	// 颜色 ****************************************************

	// 边距 ****************************************************
	MARGIN: 10,
	// 边距 ****************************************************

	// 阴影 ****************************************************
	SHADOW_BOTTOM: '0 2px 2px rgba(0, 0, 0, 0.2)',
	// 阴影 ****************************************************

	// 边框 ****************************************************
	BORDER_COLOR: '#aaa',
	BORDER_COLOR_MINOR_LIGHT: '#ddd',
	BORDER_COLOR_LIGHT: '#eee',
	BORDER_WIDTH: 1,
	BORDER_STYLE: 'solid',
	BORDER_RADIUS: 4,
	BORDER_RADIUS_LARGE: 10,
	BORDER: '1px solid #aaa',
	BORDER_MINOR_LIGHT: '1px solid #ddd',
	BORDER_LIGHT: '1px solid #eee',
	// 边框 ****************************************************

	// 对话框 ****************************************************
	BACKDROP_COLOR: 'transparent',

	// DIALOG ****************************************************
	DIALOG_Z_INDEX: 1000,
	DIALOG_SHADOW: '4px 8px 8px rgba(0, 0, 0, 0.4)',
	// DIALOG ****************************************************

	// ALERT ****************************************************
	ALERT_Z_INDEX: 10000,
	ALERT_SHADOW: '4px 8px 8px rgba(0, 0, 0, 0.2)',
	ALERT_HINT_BACKGROUND_COLOR: 'rgba(255,249,224, 0.8)',
	ALERT_HINT_ICON_COLOR: 'rgba(253, 153, 46, 0.8)',
	// ALERT ****************************************************

	// 对话框 ****************************************************

	// 组件 ****************************************************
	ROW_HEIGHT: 40,
	MIDDLE_HEIGHT: 32,
	SHORT_HEIGHT: 24,
	ICON_WIDTH: 40,
	// 输入框 ****************************************************
	INPUT_MAX_WIDTH: 420,
	INPUT_VERTICAL_PADDING: 0,
	INPUT_HORIZONTAL_PADDING: 10,
	INPUT_HEIGHT: 44,
	INPUT_LINE_HEIGHT: 44,
	INPUT_VERTICAL_MARGIN: 4,
	INPUT_BACKGRAOUND_COLOR: '#fff',
	INPUT_FOCUS_BORDER_COLOR: 'rgba(10, 157, 210, 0.8)',
	DATE_CARET_COLOR: '#999',
	DATE_CARET_SIZE: 20,
	// 输入框 ****************************************************
	// 下拉框 ****************************************************
	SELECT_CARET_COLOR: '#999',
	SELECT_CARET_SIZE: 12,
	SELECT_CARET_RIGHT: 6,
	// 下拉框 ****************************************************
	// 按钮 ****************************************************
	BTN_VERTICAL_PADDING: 5,
	BTN_HORIZONTAL_PADDING: 10,
	BTN_LINE_HEIGHT: 22,
	BTN_HEIGHT: 44,
	BTN_GROUP_GAP: 5,
	BTN_GROUP_GAP_COLOR: 'rgba(212, 212, 212, 0.3)',
	// 按钮 ****************************************************
	// 开关按钮 ****************************************************
	TOGGLE_VERTICAL_PADDING: 5,
	TOGGLE_HEIGHT: 32,
	TOGGLE_WIDTH: 48,
	TOGGLE_SLIDER_WIDTH: 24,
	TOGGLE_BORDER_COLOR: '#ddd',
	TOGGLE_BACKGROUND_COLOR: '#b3b3b3',
	TOGGLE_SLIDER_COLOR: '#eee',
	TOGGLE_TRUE_BORDER_COLOR: '#FF5640', // '#0a9dd2',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#FF5640', // '#0a9dd2',
	TOGGLE_TRUE_SLIDER_COLOR: '#eee',
	// 开关按钮 ****************************************************
	// 勾选框 ****************************************************
	CHECKBOX_MARGIN_RIGHT: 12,
	// 勾选框 ****************************************************
	// Markdown ****************************************************
	MARKDOWN_BODY_LINE_HEIGHT: 18,
	MARKDOWN_BODY_FONT_SIZE: 12,
	// Markdown ****************************************************
	// 表格 ****************************************************
	SPREAD_EVEN_BG_COLOR: '#efefef',
	SPREAD_HOVER_BG_COLOR: '#e5e5e5',
	// 表格 ****************************************************

	// 面板 ****************************************************
	PANEL_PADDING: 8,
	PANEL_BACKGROUND_COLOR: '#fff',
	PANEL_CELL_HORIZONTAL_GAP: 15,
	PANEL_CELL_VERTICAL_GAP: 8,
	PANEL_HEADER_HEIGHT: 32,
	// 面板 ****************************************************

	// 容器 ****************************************************
	CONTAINER_CELL_HORIZONTAL_GAP: 20,
	CONTAINER_CELL_VERTICAL_GAP: 20,
	// 容器 ****************************************************

	// 格子 ****************************************************
	CELL_INNER_HORIZONTAL_GAP: 10,
	CELL_INNER_VERTICAL_GAP: 0,
	CELL_BOTTOM_BORDER_GAP: 4,
	// 格子 ****************************************************

	// 组件 ****************************************************
	PAGINATION_BTN_HEIGHT: 32
	// 桌面+移动 ************************************************
} as StyledPropsTheme;
