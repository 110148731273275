import { StyledPropsCss } from '@my-types';
import { Styled } from '@common/3rd';

const CheckBox = Styled.input.attrs({
	type: 'checkbox'
})`
	display: block;
	position: relative;
	outline: none;
	margin: 0;
	height: ${props => props.theme.INPUT_HEIGHT}px;
	margin-right: ${props => props.theme.CHECKBOX_MARGIN_RIGHT}px;
	cursor: pointer;

	&[disabled],
	&[readOnly],
	&[readonly] {
		background-color: ${props => props.theme.COLOR_DISABLED};
	}

	${(props: StyledPropsCss) =>
		props.css ? props.css(props, (props as any).theme) : ''}}
`;

export const CHECKBOX = CheckBox;
