// IMPORTANT 这里不能随便import, 会引起不必要的打包引入
import * as History from 'history';
import * as StyledFunctions from 'styled-components';

/** 二维码参数 */
export type QrcodeOptions = {
	minVersion?: number;
	maxVersion?: number;
	ecLevel?: string;
	size?: number;
	/** color */
	fill?: string;
	background?: string;
	radius?: number;
	quiet?: number;
	mode?: number;
	mSize?: number;
	mPosX?: number;
	mPosY?: number;
	fontname?: string;
	fontcolor?: string;
	label?: string;
	image?: string;
};

/** 二维码产生器 */
export interface QRCodeGeneratorFunc {
	generate(text: string, options: QrcodeOptions): Promise<void>;

	getImage(): string;
}

/** 二维码模式 */
export class modes {
	NORMAL: number = 0;
	DRAW_WITH_LABEL_STRIP: number = 1;
	DRAW_WITH_LABEL_BOX: number = 2;
	DRAW_WITH_IMAGE_STRIP: number = 3;
	DRAW_WITH_IMAGE_BOX: number = 4;
}

/** 二维码质量等级 */
export class ecLevel {
	LOW: string = 'L';
	MEDIUM: string = 'M';
	QUARTILE: string = 'Q';
	HIGH: string = 'H';
}

/** 二维码库 */
export type QRCodeGeneratorLib = {
	qrcode: QRCodeGeneratorFunc & any;
	modes: modes;
	ecLevel: ecLevel;
};

export type ImageCompressorOptions = {
	toWidth?: number;
	toHeight?: number;
	/** default png */
	mimeType?: 'image/png' | 'image/jpeg';
	mode: 'strict' | 'stretch';
	/** 0 - 1, default 1 */
	quality?: number;
	/** default false */
	grayScale?: boolean;
	/** default false */
	sepia?: boolean;
	/** default false */
	threshold?: number | boolean;
	/** default false */
	vReverse?: boolean;
	/** default false */
	hReverse?: boolean;
	/** default low */
	speed?: 'low' | 'high';
};

export interface ImageCompressorLib {
	new(): ImageCompressorLib;

	run: (
		image: string,
		options: ImageCompressorOptions,
		callback: (compressedImage: string) => void
	) => void;
}

/** 标准省份/城市/区县联动结构 */
export type StandardChinaRegionsType = {
	readonly PROVINCES: CodeItem[];
	readonly CITIES: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	readonly DISTRICTS: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	getCityList: (provinceCode: string | number) => CodeItem[];
	getDistrictList: (cityCode: string | number) => CodeItem[];
};

// 数据模型 *********************************************
/** 代理模型 */
export type ProxiedModel = {
	$$emitter: any;
	/** 返回自己 */
	$$on: (prop: string, listener: ModelDataChangeEventListener) => any;
	/** 返回自己 */
	$$off: (prop: string, listener: ModelDataChangeEventListener) => any;
	$$createEvent: (
		model: any,
		prop: string,
		oldValue: any,
		newValue: any
	) => ModelDataChangedEvent;
	$$fireEvent: (prop: string, oldValue: any, newValue: any) => void;
	/** 被代理的对象 */
	$$model: any;
	/** 是否是被代理过的对象, 如果没有被代理过, 通常值是undefined. 被代理过, 值是true */
	$$proxied: boolean;
	[propName: string]: any;
};
/** 模型属性值变化事件 */
export type ModelDataChangedEvent = {
	model: any;
	prop: string;
	oldValue?: any;
	newValue?: any;
};
/** 模型属性值变化监听器 */
export type ModelDataChangeEventListener = (
	event: ModelDataChangedEvent
) => void;
/** 简单属性 */
export type ParsedIdSimple = {
	id: string;
	type: 'simple';
};
/** 索引属性 */
export type ParsedIdIndex = {
	id: string;
	type: 'index';
	index: number;
};
/** 表达式属性 */
export type ParsedIdExpression = {
	id: string;
	type: 'expression';
	exact: boolean;
	filter: FilterExpression | FilterExpressionSimple;
};
/** 简单表达式解析结果 */
export type FilterExpressionSimple = {
	prop: string;
	operator: '=' | '==' | '<>' | '!=' | '>' | '>=' | '<' | '<=' | '===' | '!==';
	value: string;
};
/** 表达式 */
export type FilterExpression = {
	type: 'and' | 'or';
	expressions: (FilterExpression | FilterExpressionSimple)[];
};
/** 过滤器规则参数 */
export type FilterRuleOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	rules: FilterRuleFunction[];
	caller: ModelWidgetInterface & WidgetHelped;
};
/** 过滤器规则函数 */
export type FilterRuleFunction = (
	caller: ModelWidgetInterface & WidgetHelped,
	options: FilterRuleOptions
) => boolean;
/** 复杂过滤器规则 */
export type ComplexFilterRule = {
	and?: string | string[];
	or?: string | string[];
};
/** 可过滤定义 */
export type Filterable = {
	on?:
	| string
	| string[]
	| FilterRuleFunction
	| FilterRuleFunction[]
	| ComplexFilterRule;
};
export type VisibleFilterable = { do?: boolean } & Filterable;
export type EditableFilterable = { do?: boolean } & Filterable;
/** 校验规则函数参数 */
export type CheckRuleOptions = {
	caller: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	value: any;
	arrayHolder: any;
};
/** 预置校验规则函数参数 */
export type PredefinedCheckRuleOptions = {
	rule: CheckRule;
	params: any;
} & CheckRuleOptions;
/**
 * 自定义校验规则
 *
 * @return true表示通过, false表示没有通过校验
 */
export type CheckRuleFuncion = (options: CheckRuleOptions) => boolean | string;

/** 预定义的校验规则名称 */
export enum PredefinedCheckRules {
	REQUIRED = 'required',
	REGEXP = 'regexp',
	DATE_RANGE = 'dateRange',
	BANK_CARD_NUMBER = 'bankCardNumber',
	GENDER_ON_RELATION = 'genderOnRelation',
	AGE_RANGE = 'ageRange',
	NUMERIC_RANGE = 'numericRange',
	BMI = 'bmi',
	RELATION_WITH_POLICY_HOLDER = 'relationWithPolicyHolder',
	RELATION_WITH_POLICY_HOLDER_DUPLICATION = 'relationWithPolicyHolderDuplication',
	RELATION_WITH_MAIN_INSURED = 'relationWithMainInsured',
	RELATION_WITH_MAIN_INSURED_DUPLICATION = 'relationWithMainInsuredDuplication',
	RELATION_WITH_PH_AND_MI = 'relationWithPHAndMI',
	RELATION_WITH_INSURED = 'relationWithInsured',
	RELATION_WITH_INSURED_DUPLICATION = 'relationWithInsuredDuplication',
	ID_NUMBER = 'idNumber',
	ID_NUMBER_MATCH = 'idNumberMatch',
	PERMANENT_RESIDENT_PERMIT = 'permanentResidentPermit',
	CHINA_PASSPORT = 'chinaPassport',
	POST_CODE = 'postcode',
	NAME = 'name'
}

/** 校验规则, 必要项 */
export type CheckRuleRequired = {
	do: PredefinedCheckRules.REQUIRED;
};


export type CheckRuleName = {
	do: PredefinedCheckRules.NAME;
};

/** 预置正则校验规则名称, 参考predefined-regexp.tsx文件 */
export enum CheckRuleRegexpPredefinedSet {
	EMAIL = 'EMAIL',
	MOBILE = 'MOBILE',
	BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
	MILITARY_CERTIFICATE = 'MILITARY_CERTIFICATE',//军官证校验
	SOLDIER_CERTIFICATE = 'SOLDIER_CERTIFICATE',//士兵证校验
	IDENTITY_CARD = 'IDENTITY_CARD',
	POSTCODE = 'POSTCODE',
	CHINA_PASSPORT = 'CHINA_PASSPORT',
	TW_ID_CARD = 'TW_ID_CARD',
	TW_MAINLAND = 'TW_MAINLAND',
	HK_MAINLAND = 'HK_MAINLAND',
	DATE = 'DATE',
	SLOGAN_LENGTH = 'SLOGAN_LENGTH',
	HEIGHT_IN_CM = 'HEIGHT_IN_CM',
	HEIGHT_IN_CM_FRACTION = 'HEIGHT_IN_CM_FRACTION',
	HEIGHT_IN_CM_FRACTION2 = 'HEIGHT_IN_CM_FRACTION2',
	WEIGHT_IN_CATTY = 'WEIGHT_IN_CATTY',
	WEIGHT_IN_KG = 'WEIGHT_IN_KG',
	WEIGHT_IN_KG_FRACTION = 'WEIGHT_IN_KG_FRACTION',
	WEIGHT_IN_KG_FRACTION2 = 'WEIGHT_IN_KG_FRACTION2',
	ENGLISH_NAME = 'ENGLISH_NAME',
	DIGITS = 'DIGITS',
	FLOAT = 'FLOAT'
}

/** 校验规则, 正则表达式 */
export type CheckRuleRegexp = {
	do: PredefinedCheckRules.REGEXP;
	with:
	| {
		regexp: RegExp | CheckRuleRegexpPredefinedSet;
		contrary?: boolean;
	}
	| RegExp
	| CheckRuleRegexpPredefinedSet;
};
/** 校验规则, 日期区间 */
export type CheckRuleDateRange = {
	do: PredefinedCheckRules.DATE_RANGE;
	with: {
		/** 与当前日期进行比较, 使用startFrom/endWith的boolean值指定来表示与当前日期的关系 */
		now?: boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		startFrom?: string | boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		endWith?: string | boolean;
		/** 是否允许相等, 默认true */
		canEqual?: boolean;
		/** 指定用于比较的时间格式, 默认只有年月日, 时分秒都会被清除, 不参与比较 */
		format?: string;
		/** 指定被比较值的计算规则 */
		offset?: string;
	};
};
export type CheckRuleAgeRange = {
	do: PredefinedCheckRules.AGE_RANGE;
	with: string;
};
export type CheckRuleNumericRange = {
	do: PredefinedCheckRules.NUMERIC_RANGE;
	with: string;
};
/** 银行卡号校验 */
export type CheckRuleBankCardNumber = {
	do: PredefinedCheckRules.BANK_CARD_NUMBER;
	with?: {
		cardTypePropName?: string;
		bankCodePropName?: string;
		codeMapping?: { [propName: string]: string | number };
	};
};
export type CheckRuleGenderOnRelation = {
	do: PredefinedCheckRules.GENDER_ON_RELATION;
	with?: {
		relationPropName?: string;
	};
};
export type CheckRuleBmi = {
	do: PredefinedCheckRules.BMI;
	with: {
		range: string;
		heightPropName?: string;
		weightPropName?: string;
	};
};

export enum RelationTypeWith {
	SELF = '1', // 本人
	SPOUSE = '2', // 配偶
	CHILD = '3', // 子女
	PARENTS = '4', // 父母
	GRAND_PARENT = '5', // (外)祖父母
	GRAND_CHILDREN = '6', // 孙辈
	FOSTER_PARENT = '7', // 养父母
	EMPLOY = '8', // 雇佣
	HEIR_AT_LAW = '9', // 法定继承人
	SIBLING = '10', // 兄弟姐妹
	WIFE = '11', // 妻子
	HUSBAND = '12', // 丈夫
	SON = '13', // 儿子
	DAUGHTER = '14', //女儿
	FATHER = '15', // 父亲
	MOTHER = '16', // 母亲
	GRAND_SON_PARTRILOCAL = '17', // 孙子
	GRAND_DAUGHTER_PARTRILOCAL = '18', // 孙女
	GRAND_SON_MATRIARCHAL = '19', // 外孙
	GRAND_DAUGHTER_MATRIARCHAL = '20', // 外孙女
	GRAND_FATHER_PARTRILOCAL = '21', // 祖父
	GRAND_MOTHER_PARTRILOCAL = '22', // 祖母
	GRAND_FATHER_MATRIARCHAL = '23', // 外祖父
	GRAND_MOTHER_MATRIARCHAL = '24', // 外祖母
	UNCLE = '25', // 叔伯
	KINSFOLK = '26', // 亲属
	OTHER = '999' // 其他
}

export enum RelationTypeWithCheckType {
	AGE = 1,
	GENDER = 2
}

export type CheckRuleRelationWithPolicyHolder = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
export type CheckRuleRelationWithPolicyHolderDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
export type CheckRuleRelationWithMainInsured = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithMainInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsured = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName?: string;
		insuredPropName?: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithPHAndMI = {
	do: PredefinedCheckRules.RELATION_WITH_PH_AND_MI;
	with: {
		/** 附加被保人与主保险人关系栏位 */
		relationPropName: string;
	};
};
export type CheckRuleIdNumber = {
	do: PredefinedCheckRules.ID_NUMBER;
	// idType字段名
	with: string;
};

export enum IdNumberMatchCheckType {
	DATE_OF_BIRTH = 1,
	GENDER = 2
}

export type CheckRuleIdNumberMatch = {
	do: PredefinedCheckRules.ID_NUMBER_MATCH;
	with: {
		type: IdNumberMatchCheckType;
		// 属性前缀
		prefix?: string;
	};
};
export type CheckRuleChinaPassport = {
	do: PredefinedCheckRules.CHINA_PASSPORT;
	// idType字段名
	with: string;
};
export type CheckRulePermanentResidentPermit = {
	do: PredefinedCheckRules.PERMANENT_RESIDENT_PERMIT;
	// 对象id
	with: string;
};
export type CheckRulePostCode = {
	do: PredefinedCheckRules.POST_CODE;
	// 对象id
	with: string;
};

/** 校验规则, 自定义函数 */
export type CheckRuleByFunction = {
	/** 执行的校验规则, 如果是string, 则执行预置规则 */
	do: CheckRuleFuncion;
};
export type CheckRuleMessage = { msg?: string };
/** 校验规则 */
export type CheckRule = (
	| CheckRuleRequired
	| CheckRuleName
	| CheckRuleRegexp
	| CheckRuleDateRange
	| CheckRuleAgeRange
	| CheckRuleNumericRange
	| CheckRuleBankCardNumber
	| CheckRuleGenderOnRelation
	| CheckRuleBmi
	| CheckRuleRelationWithPolicyHolder
	| CheckRuleRelationWithPolicyHolderDuplication
	| CheckRuleRelationWithMainInsured
	| CheckRuleRelationWithMainInsuredDuplication
	| CheckRuleRelationWithInsured
	| CheckRuleRelationWithInsuredDuplication
	| CheckRuleRelationWithPHAndMI
	| CheckRuleIdNumber
	| CheckRuleIdNumberMatch
	| CheckRuleChinaPassport
	| CheckRulePermanentResidentPermit
	| CheckRulePostCode
	| CheckRuleByFunction) &
	CheckRuleMessage &
	Filterable;
// 数据模型 *********************************************

// AJAX通讯 *********************************************
/** 请求HEADERS */
export type AjaxHeaders = {
	'Content-Type'?: string | boolean;
	[propName: string]: string | undefined | null | boolean;
};
/** 请求返回错误项目 */
export type AjaxResponseErrorItem = {
	code: string;
	description?: string | null;
};
/** 请求返回主体 */
export type AjaxResponseBody = {
	returnCode?: string | null;
	body?: object;
	errors?: AjaxResponseErrorItem[];
};
/** 标准请求返回 */
export type AjaxResponse = {
	headers?: AjaxHeaders;
	body?: AjaxResponseBody | any | null;
	status: number;
	statusText: string;
	error?: Error;
};

/** 请求方式 */
export enum AjaxMethod {
	GET = 'get',
	POST = 'post'
}

/** 请求参数 */
export type AjaxOptions = {
	method?: AjaxMethod;
	headers?: AjaxHeaders;
	credentials?: string;
	mode?: string;
	redirect?: string;
	cache?: string;

	dataType?: string;
	ignoreAuth?: boolean;
	ignoreRetrieveAccount?: boolean;
};
/** 请求响应处理器 */
export type AjaxHandler = (response: AjaxResponse) => void;
// AJAX通讯 *********************************************

// 账户 *********************************************
export type EnvAccount = {
	tenantId?: string | number | null;
	tenantCode?: string | null;
	organizationId?: string | number | null;
	type?: string | number | null;
	accountId?: string | number | null;
	accountName?: string | null;
	nickname?: string | null;
	avatar?: string;
	menus?: string | null;
	salesAreaCode?: string | null;
	mobile?: string;
	email?: string;
	showMall?: boolean;
	sourceType?: string | number;
	entProductCount?: number;
	maskMobile?: string;
	enableSendEmail?: boolean;
	organCode?: string;
};
// 账户 *********************************************

// 应用 *********************************************
export interface Application {
	getTheme(): StyledPropsTheme;

	changeTheme(theme: string): void;
changeThemeColor(themeColor?: string): void;

	getThemeName(): string;

	setScrollDisabled(shown?: boolean): void;

	isScrollDisabled(): boolean;

	getHistory(): History.History;
}

// 应用 *********************************************

// STYLED-COMPONENTS *********************************************
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
export type StyledPropsTheme = {
	/** 桌面HTML水平外边距 */
	readonly DESK_HTML_HORIZONTAL_MARGIN: number;
	/** 桌面滚动条宽度 */
	readonly DESK_HTML_SCROLL_WIDTH: number;
	/** 桌面按钮最小宽度 */
	readonly DESK_BTN_MIN_WIDTH: number;
	/** 桌面格子内部标签的宽度 */
	readonly DESK_CELL_INNER_LABEL_WIDTH: number;
	/** 错误提示淡出动画时间长度 */
	readonly ERROR_REMINDER_FADE_OUT_DURATION: string;
	/** 桌面对话框横向内边距 */
	readonly DESK_DIALOG_HORIZONTAL_PADDING: number;
	/** 桌面页眉高度 */
	readonly DESK_PAGE_HEADER_HEIGHT: number;
	/** 手机HTML水平外边距 */
	readonly MOBILE_HTML_HORIZONTAL_MARGIN: number;
	/** 页头层 */
	readonly PAGE_HEADER_Z_INDEX: number;
	/** 页脚层 */
	readonly PAGE_FOOTER_Z_INDEX: number;
	/** HTML字体 */
	readonly FONT_SIZE_HTML: number;
	/** 超大字体 */
	readonly FONT_SIZE_SUPER: number;
	/** 大字体4 */
	readonly FONT_SIZE_L4: number;
	/** 大字体3 */
	readonly FONT_SIZE_L3: number;
	/** 大字体2 */
	readonly FONT_SIZE_L2: number;
	/** 大字体 */
	readonly FONT_SIZE_LARGE: number;
	/** 标准字体 */
	readonly FONT_SIZE: number;
	/** 小字体 */
	readonly FONT_SIZE_SMALL: number;
	/** 标准字体 */
	readonly FONT_FAMILY: string;
	/** 标准字体称重 */
	readonly FONT_WEIGHT: number;
	/** 轻量加粗字体称重 */
	readonly FONT_WEIGHT_BOLD_LIGHT: number;
	/** 标准加粗字体称重 */
	readonly FONT_WEIGHT_BOLD: number;
	/** 标准字体色 */
	readonly FONT_COLOR: string;
	/** 淡化字体色 + 1 */
	readonly FONT_COLOR_LIGHT: string;
	/** 淡化字体色 + 2 */
	readonly FONT_COLOR_LIGHT2: string;
	/** 标准背景色 */
	readonly COLOR_BACKGROUND: string;
	/** 失效色 */
	readonly COLOR_DISABLED: string;
	/** 失效背景色 */
	readonly COLOR_DISABLED_BACKGROUND: string;
	/** 标准色 */
	readonly COLOR_PRIMARY: string;
	/** 标准色75%透明 */
	readonly COLOR_PRIMARY_LIGHT_O75: string;
	/** 标准色10%透明 */
	readonly COLOR_PRIMARY_LIGHT_010: string;
	/** 标准色淡10% */
	readonly COLOR_PRIMARY_LIGHT: string;
	/** 反转色 */
	readonly COLOR_REVERSED: string;
	/** 放弃色 */
	readonly COLOR_WAIVE: string;
	/** 放弃色淡10% */
	readonly COLOR_WAIVE_LIGHT: string;
	/** 消息色 */
	readonly COLOR_INFO: string;
	/** 危险色 */
	readonly COLOR_DANGER: string;
	/** 危险色淡10% */
	readonly COLOR_DANGER_LIGHT: string;
	/** 错误色 */
	readonly COLOR_ERROR: string;
	/** 成功色 */
	readonly COLOR_SUCCESS: string;
	/** 询问色 */
	readonly COLOR_QUESTION: string;
	/** 警告色 */
	readonly COLOR_WARN: string;
	/** 价格色 */
	readonly COLOR_PRICE: string;
	/** 标准边距, 适用于上下左右 */
	readonly MARGIN: number;
	/** 下方阴影 */
	readonly SHADOW_BOTTOM: string;
	/** 边框色 */
	readonly BORDER_COLOR: string;
	/** 轻度淡化边框色 */
	readonly BORDER_COLOR_MINOR_LIGHT: string;
	/** 淡化边框色 */
	readonly BORDER_COLOR_LIGHT: string;
	/** 边框宽度 */
	readonly BORDER_WIDTH: number;
	/** 边框样式 */
	readonly BORDER_STYLE: string;
	/** 边框圆角 */
	readonly BORDER_RADIUS: number;
	/** 边框圆角 大号 */
	readonly BORDER_RADIUS_LARGE: number;
	/** 边框 */
	readonly BORDER: string;
	/** 轻度淡化边框 */
	readonly BORDER_MINOR_LIGHT: string;
	/** 淡化边框 */
	readonly BORDER_LIGHT: string;
	/** 弹出层遮罩色 */
	readonly BACKDROP_COLOR: string;
	/** 对话框层 */
	readonly DIALOG_Z_INDEX: number;
	/** 对话框阴影 */
	readonly DIALOG_SHADOW: string;
	/** 警示框层 */
	readonly ALERT_Z_INDEX: number;
	/** 警示框阴影 */
	readonly ALERT_SHADOW: string;
	/** 警示框HINT背景色 */
	readonly ALERT_HINT_BACKGROUND_COLOR: string;
	/** 警示框HINT图标色 */
	readonly ALERT_HINT_ICON_COLOR: string;
	/** 行高 */
	readonly ROW_HEIGHT: number;
	/** 中等行高 */
	readonly MIDDLE_HEIGHT: number;
	/** 矮的行高 */
	readonly SHORT_HEIGHT: number;
	/** 图标宽度 */
	readonly ICON_WIDTH: number;
	/** 输入框最长宽度 */
	readonly INPUT_MAX_WIDTH: number;
	/** 输入框上下内边距 */
	readonly INPUT_VERTICAL_PADDING: number;
	/** 输入框左右内边距 */
	readonly INPUT_HORIZONTAL_PADDING: number;
	/** 输入框高度 */
	readonly INPUT_HEIGHT: number;
	/** 输入框文字行高 */
	readonly INPUT_LINE_HEIGHT: number;
	/** 输入框上下外边距 */
	readonly INPUT_VERTICAL_MARGIN: number;
	/** 输入框背景色 */
	readonly INPUT_BACKGRAOUND_COLOR: string;
	/** 输入框获取焦点后边框色 */
	readonly INPUT_FOCUS_BORDER_COLOR: string;
	/** 日期输入框三角符号颜色 */
	readonly DATE_CARET_COLOR: string;
	/** 日期输入框三角符号尺寸 */
	readonly DATE_CARET_SIZE: number;
	/** 下拉框三角符号颜色 */
	readonly SELECT_CARET_COLOR: string;
	/** 下拉框三角符号尺寸 */
	readonly SELECT_CARET_SIZE: number;
	/** 下拉框三角符号离右边距离 */
	readonly SELECT_CARET_RIGHT: number;
	/** 按钮上下内边距 */
	readonly BTN_VERTICAL_PADDING: number;
	/** 按钮左右内边距 */
	readonly BTN_HORIZONTAL_PADDING: number;
	/** 按钮文字行高 */
	readonly BTN_LINE_HEIGHT: number;
	/** 按钮高度 */
	readonly BTN_HEIGHT: number;
	/** 按钮组内部按钮间距 */
	readonly BTN_GROUP_GAP: number;
	/** 按钮组内部按钮间隔色 */
	readonly BTN_GROUP_GAP_COLOR: string;
	/** 开关按钮上下内边距 */
	readonly TOGGLE_VERTICAL_PADDING: number;
	/** 开关按钮高度 */
	readonly TOGGLE_HEIGHT: number;
	/** 开关按钮宽度 */
	readonly TOGGLE_WIDTH: number;
	/** 开关按钮滑块宽度 */
	readonly TOGGLE_SLIDER_WIDTH: number;
	/** 开关按钮边框色 */
	readonly TOGGLE_BORDER_COLOR: string;
	/** 开关按钮背景色 */
	readonly TOGGLE_BACKGROUND_COLOR: string;
	/** 开关按钮滑块色 */
	readonly TOGGLE_SLIDER_COLOR: string;
	/** 开关按钮true边框色 */
	readonly TOGGLE_TRUE_BORDER_COLOR: string;
	/** 开关按钮true背景色 */
	readonly TOGGLE_TRUE_BACKGROUND_COLOR: string;
	/** 开关按钮true滑块色 */
	readonly TOGGLE_TRUE_SLIDER_COLOR: string;
	/** 勾选框右边外边距 */
	readonly CHECKBOX_MARGIN_RIGHT: number;
	/** markdown正文行高 */
	readonly MARKDOWN_BODY_LINE_HEIGHT: number;
	/** markdown正文文字尺寸 */
	readonly MARKDOWN_BODY_FONT_SIZE: number;
	/** 表格双数行背景色 */
	readonly SPREAD_EVEN_BG_COLOR: string;
	/** 表格行Hover背景色 */
	readonly SPREAD_HOVER_BG_COLOR: string;
	/** 面板内边距 */
	readonly PANEL_PADDING: number;
	/** 面板背景色 */
	readonly PANEL_BACKGROUND_COLOR: string;
	/** 面板格子横向间距 */
	readonly PANEL_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly PANEL_CELL_VERTICAL_GAP: number;
	/** 面板头高度 */
	readonly PANEL_HEADER_HEIGHT: number;
	/** 面板格子横向间距 */
	readonly CONTAINER_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly CONTAINER_CELL_VERTICAL_GAP: number;
	/** 格子内部横向间距 */
	readonly CELL_INNER_HORIZONTAL_GAP: number;
	/** 格子内部纵向间距 */
	readonly CELL_INNER_VERTICAL_GAP: number;
	/** 格子内部底边间隔, 只在Labelling是生效 */
	readonly CELL_BOTTOM_BORDER_GAP: number;
	/** 分页按钮高度 */
	readonly PAGINATION_BTN_HEIGHT: number;
};

/** 可自定义CSS属性 */
export interface StyledPropsCss {
	/** CSS */
	css?: (props: any, theme: StyledPropsTheme) => string;
}

/** 主题属性 */
export interface StyledProps extends StyledPropsCss {
	theme: StyledPropsTheme;
}

/** 全局样式属性 */
export type GlobalStyledProps = {
	scrollDisabled?: boolean;
};

export type StyledDIV = StyledFunctions.StyledComponent<'div', any, {}, never>;
export type StyledSPAN = StyledFunctions.StyledComponent<'span',
	any,
	{},
	never>;
export type StyledBUTTON = StyledFunctions.StyledComponent<'a', any, {}, never>;
export type StyledINPUT = StyledFunctions.StyledComponent<'input',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledTEXTAREA = StyledFunctions.StyledComponent<'textarea',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledSELECT = StyledFunctions.StyledComponent<'select',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledI = StyledFunctions.StyledComponent<'i', any, {}, never>;
// STYLED-COMPONENTS *********************************************

// 基本组件属性 *********************************************
/** font-awesome图标属性 */
export interface FaIconProps extends StyledPropsCss {
	block?: boolean;
	color?: string;
	spin?: boolean;
	icon: string;
	className?: string;
	onClick?: (evt?: MouseEvent) => void;
}

/** 输入框属性 */
export interface InputProps extends StyledPropsCss {
	type: string;
	placeholder?: string;
	maxLength?: number;
	disabled?: boolean;
	className?: string;
	name?: string;
	value?: any;
	autoComplete?: string;
	readOnly?: boolean;
	onFocus?: (evt?: FocusEvent) => void;
	onBlur?: (evt?: FocusEvent) => void;
	onChange?: (evt: React.FormEvent<HTMLInputElement>) => void;
}

/** 可点击的组件 */
export interface Clickable {
	onClick?: (
		invoker: ModelWidgetInterface & WidgetHelped,
		evt: MouseEvent
	) => void;
}

export enum ButtonType {
	PRIMARY = 'primary',
	WAIVE = 'waive',
	DANGER = 'danger',
	DEFAULT = 'default',
	BORDERED = 'bordered'
}

export enum CheckValueType {
	BOOLEAN = 1,
	YES_NO = 2
}

/** 按钮属性 */
export interface ButtonProps extends StyledPropsCss, Clickable {
	type?: ButtonType;
	disabled?: boolean;
}

// 基本组件属性 *********************************************

// 组件 ********************************************
/** 组件属性 */
export interface IWidgetRouterHelper {
	forward(): this;

	back(): this;

	go(steps: number): this;

	pushRedirect(to: string): void;

	replaceRedirect(to: string): void;
}

export type OPTIONS = AjaxOptions & {
	ignore4xx?: boolean;
	ignore5xx?: boolean;
	ignoreFail?: boolean;
	loading?: boolean;
};

export interface IWidgetAjaxHelper {
	post(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	get(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	all(promises: Promise<any>[]): Promise<any[]>;
}

export interface WidgetHelpers {
	getRouter(): IWidgetRouterHelper;

	getAjax(): IWidgetAjaxHelper;
}

export interface WidgetHelped {
	getHelpers(): WidgetHelpers;
}

export type WidgetProps = StyledPropsCss & {
	theme?: StyledPropsTheme;
};
export type ReadonlyProps = {
	/** 是否只读, 没有设置默认可操作 */
	readonly?: boolean;
};
export type InitProcessor = (options: InitProcessEvent) => void;
export type InitProcessEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	[propName: string]: any;
}
export type ForceListener = (options: ForceListenEvent) => void;
export type ForceListenEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	arrayHolder: any;
	value: any;
	oldValue: any;
};

/** 模型组件属性 */
export interface ModelWidgetInterface {
	getPropName(): string;

	getModel(): any;

	getRootModel(): any;

	getArrayHolder(): any;
}

export type ModelWidgetProps = {
	/** 属性名, 别名. 没有propName的时候生效 */
	id?: string;
	/** 属性名 */
	propName?: string;
	/** 模型 */
	model?: object;
	/** 根模型 */
	root?: object;
	/** 如果本组件是数组元素, 则会有数组持有者 */
	arrayHolder?: object;
	/** 是否显示 */
	visible?: false | string | string[] | Filterable | Filterable[];
	/** 监听器, 在值变化的时候触发 */
	listen?: ForceListener;
	/** 组件初始处理器 */
	process?: InitProcessor;
} & WidgetProps;
/** 可校验组件属性 */
export type CheckableWidgetProps = {
	/** 校验规则 */
	check?: boolean | CheckRule | CheckRule[];
	checkStages?: string | string[];
	/** 校验规则前置条件, 不满足则不触发校验规则 */
	triggerCheckOn?: string | string[] | Filterable | Filterable[];
} & ModelWidgetProps;
/** 可校验组件状态 */
export type CheckableWidgetState = {
	/** 是否因为某种原因导致校验失败, 原因无论 */
	checkFailOnWhatever?: boolean;
	/** 校验失败原因信息 */
	checkFailMsg?: string;
};
/** 可校验组件校验结果 */
export type CheckableWidgetCheckResult = {
	/** 未通过的校验规则 */
	rule?: CheckRule;
	/** 什么组件发生校验失败 */
	where: any;
};
/** 码表元素 */
export type CodeItem = {
	/** 显示标签 */
	l: string;
	/** 码值 */
	v: string | number | boolean;
	/** 上级值 */
	p?: string | number;
};
/** 码表组件属性 */
export type CodesWidgetProps = {
	clearable?: boolean;
	codes: CodeItem[];
};
export type CodesFilter =
	| {
		filter: (
			codes: any,
			invoker: ModelWidgetInterface & WidgetHelped
		) => CodeItem[];
		on: string;
	}
	| ((codes: any, invoker: ModelWidgetInterface & WidgetHelped) => CodeItem[]);

/** 码表组件 */
export interface CodesWidget {
	isClearable(): boolean;

	getCodes(): CodeItem[];
}

/** 错误提醒组件属性 */
export type ErrorReminderProps = {
	/** 提醒内容 */
	msg: string;
};
export type LabelDecoratorOptions = {
	root: any;
	model: any;
	arrayHolder: any;
	value: any;
	invoker: ModelWidgetInterface & WidgetHelped;
	decorated: any;
};
export type LabelDecorator = (options: LabelDecoratorOptions) => any;
export type LabelCss = (props: any, theme: StyledPropsTheme) => string;

/** 页眉内部组件 */
export type PageHeaderComponents = {
	Box: StyledDIV;
};
/** 页面主体内部组件 */
export type PageBodyComponents = {
	Box: StyledDIV;
};
/** 页脚内部组件 */
export type PageFooterComponents = {
	Box: StyledDIV;
};
/** 页面内部组件 */
export type PageComponents = {
	Box: StyledDIV;
};

// 桌面组件 *******************************************************
/** 桌面页眉内部组件 */
export type DeskPageHeaderComponents = {
	Logo: StyledDIV;
	LogoText: StyledDIV;
	HeaderTop: StyledDIV;
	Right: StyledDIV;
} & PageHeaderComponents;
/** 桌面页眉菜单集内部组件 */
export type DeskPageHeaderMenusComponents = {
	Box: StyledDIV;
};
/** 桌面页眉标准菜单内部组件 */
export type DeskPageHeaderMenuComponents = {
	Box: StyledDIV;
	SubMenus: StyledDIV;
	SubMenu: StyledDIV;
	SubMenus2: StyledDIV;
	SubMenu2: StyledDIV;
};
/** 桌面页眉右菜单内部组件 */
export type DeskPageHeaderRightMenuComponents = {
	Box: StyledDIV;
	AccountText: StyledDIV;
	SignOut: StyledDIV;
};
/** 桌面页面主体属性 */
export type DeskPageBodyProps = {
	home?: boolean | null;
	where?: string | string[] | null | { label: string, onLocClicked?: () => void } | { label: string, onLocClicked?: () => void }[];
	showLocation?: boolean | undefined;
	css?: (props: any, theme: StyledPropsTheme) => string;
	vendor?: string;
};
/** 桌面页面主体内部组件 */
export type DeskPageBodyComponents = {
	LOC: StyledDIV;
	Home: StyledSPAN;
	Menu: StyledDIV;
	HotWordWrapper: StyledDIV;
	ContentWrapper: StyledDIV & React.ComponentClass<StyledPropsCss & {
		className?: string
	}>;
	Premium: StyledDIV & React.ComponentClass<StyledPropsCss>;
	Product: StyledDIV;
	Rank: StyledDIV;
	BottomLink: StyledDIV;
	SupTip: StyledDIV;
	Overall: StyledDIV;
	Classification: StyledDIV & React.ComponentClass<StyledPropsCss>;
	TargetGroupClassification: StyledDIV & React.ComponentClass<StyledPropsCss>;
	Table: StyledDIV & React.ComponentClass<StyledPropsCss & {
		className?: string,
		gridTemplateColumns: string, count: number
	}>;
} & PageBodyComponents;
/** 桌面404页面主体内部组件 */
export type DeskNotFoundBodyComponents = {
	ICONS: StyledDIV;
	ICON: StyledSPAN;
	MSG: StyledDIV;
} & DeskPageBodyComponents;

// desk container
/** Flex容器对齐属性枚举 */
export enum FlexAlignment {
	START = 'start',
	END = 'end',
	CENTER = 'center',
	BETWEEN = 'between',
	AOURND = 'around',
	EVENLY = 'evenly'
}

/** Flex容器属性 */
export type FlexContainerProps = {
	align?: FlexAlignment;
} & ModelWidgetProps;
/** 网格容器属性 */
export type GridContainerProps = {
	/**
	 * 网格布局列数量, 如果定义了模板则不会生效.
	 * 生效时将容器按照列数量均分
	 */
	gridColumns?: number;
	/**
	 * 网格布局模板, 最优先生效
	 */
	gridTemplates?: string;
} & ModelWidgetProps;
/** 网格单元格属性 */
export type GridCellProps = {
	row?: number | string | null;
	rowSpan?: number | string | null;
	column?: number | string | null;
	columnSpan?: number | string | null;
};
/** 容器属性 */
export type ContainerProps = {
	layout?: object;
} & GridContainerProps;
/** 容器内部组件 */
export type ContainerComponents = {
	Box: StyledDIV;
};
/** 数组容器属性 */
export type ArrayContainerProps = FlexContainerProps;
/** 数组容器内部组件 */
export type ArrayContainerComponents = { Box: StyledDIV };
/** 数组容器行集合属性 */
export type ArrayContainerRowsProps = {
	nodata: ArrayContainerNoDataProps;
	sort?: (a: any, b: any) => number;
} & ModelWidgetProps;
/** 数组容器行属性 */
export type ArrayContainerRowProps = { index: number } & FlexContainerProps;
/** 数组容器行内部组件 */
export type ArrayContainerRowComponents = { Box: StyledDIV };
/** 数组容器无数据属性 */
export type ArrayContainerNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
export type ArrayContainerNoDataComponents = { Box: StyledDIV };
/** 面板属性 */
export type DeskPanelProps = {
	collapsible?: boolean;
	expanded?: boolean;
} & ModelWidgetProps;
/** 面板状态 */
export type DeskPanelState = { expanded?: boolean };
/** 面板内部组件 */
export type DeskPanelComponents = { Box: StyledDIV };
/** 面板头内部组件 */
export type DeskPanelHeaderComponents = { Box: StyledDIV };
/** 面板头标题内部组件 */
export type DeskPanelHeaderTitleComponents = { Box: StyledDIV };
/** 面板头折叠/展开图标属性 */
export type DeskPanelHeaderCollapsibleIconProps = {
	expanded: boolean;
	onClick: () => void;
} & WidgetProps;
/** 面板头折叠/展开图标内部组件 */
export type DeskPanelHeaderCollapsibleIconComponents = {
	Box: any;
};
/** 面板头扩展图标属性 */
export type DeskPanelHeaderExtIconProps = {
	onClick: () => void;
} & WidgetProps;
/** 面板头扩展图标内部组件 */
export type DeskPanelHeaderExtIconComponents = {
	Box: any;
};
/** 面板主体属性 */
export type DeskPanelBodyProps = {} & GridContainerProps;
/** 面板主体内部组件 */
export type DeskPanelBodyComponents = { Box: StyledDIV };
/** 面板主体单元格属性 */
export type DeskPanelBodyCellProps = {} & GridCellProps & FlexContainerProps;
/** 面板主体单元格内部组件 */
export type DeskPanelBodyCellComponents = { Box: StyledDIV };
/** 面板脚属性 */
export type DeskPanelFooterProps = {} & FlexContainerProps;
/** 面板脚内部组件 */
export type DeskPanelFooterComponents = { Box: StyledDIV };
/** 可以做为标签化组件父组件的面板主体单元格组件属性 */
export type DeskPanelLabelledBodyCellProps = {
	/**
	 * 标签文本
	 */
	label?: string;
	/**
	 * 标签宽度, 百分比数字
	 */
	labelWidth?: number;
} & DeskPanelBodyCellProps;
/** 可以做为标签化组件父组件的面板主体单元格组件状态 */
export type DeskPanelLabelledBodyCellState = {
	focus?: boolean;
	checkFailed?: boolean;
};
/** 可以做为标签化组件父组件的面板主体单元格组件内部组件 */
export type DeskPanelLabelledBodyCellComponents = { Box: StyledDIV };

/** 可以包含标签化组件的父组件接口 */
export interface LabellingParent {
	/** 返回当前组件的数据是否有必填要求 */
	isDataRequired(): boolean;

	/** 强制让被标签的组件获取焦点 */
	focusLabelled: () => void;
	/** 获取焦点后处理 */
	onFocusGained: () => void;
	/** 失去焦点后处理 */
	onFocusLost: () => void;
	/** 校验完毕后处理 */
	onCheckCompleted: (passed: boolean) => void;
}

/** 可以被标签化的组件属性ƒ */
export type LabellingComponentProps = {
	/** 标签化父节点 */
	labellingParent?: LabellingParent;
};
/** 按钮属性 */
export type DeskButtonProps = {
	label?: string;
	interval?: number;
	autoInterval?: true;//自动倒计时
	clickCheckerable?: true;//点击按钮时倒计时是否需要先校验
	desc?: string;
	disabled?: boolean;
} & WidgetProps &
	ButtonProps;
/** 按钮内部组件 */
export type DeskButtonComponents = {
	Box: StyledDIV;
	Button: StyledBUTTON & React.ComponentClass<ButtonProps>;
	Desc: StyledDIV;
};
/** 链接属性 */
export type DeskLinkProps = {
	label?: string;
} & WidgetProps &
	Clickable;
/** 链接内部组件 */
export type DeskLinkComponents = {
	Link: StyledBUTTON & React.ComponentClass<Clickable>;
};
/** 按钮组内部组件 */
export type DeskButtonGroupComponents = { Box: StyledDIV };
/** 桌面标签内部组件 */
export type DeskLabelProps = { label?: string } & LabellingComponentProps &
	ModelWidgetProps &
	WidgetProps;
/** 桌面标签内部状态 */
export type DeskLabelState = { focus?: boolean };
/** 桌面标签内部组件 */
export type DeskLabelComponents = { Box: StyledSPAN };
/** 桌面作为组件标签出现的标签属性 */
export type DeskLabellingLabelProps = {
	required?: boolean;
} & DeskLabelProps &
	LabellingComponentProps;
export type DeskCodesLabelProps = {} & CodesWidgetProps & DeskLabelProps;
/** 桌面输入框属性 */
export type DeskTextProps = {
	suffix?: string;
	suffixText?: string;
	rows?: number;
	placeholder?: string;
	maxLength?: number;
	autoComplete?: string;
	id?: string;
} & LabellingComponentProps &
	CheckableWidgetProps &
	ReadonlyProps;
/** 桌面输入框内部组件 */
export type DeskTextComponents = {
	Box: StyledDIV;
	Text: StyledINPUT;
	ErrorReminder: any;
	SuffixText: StyledDIV;
};
/** 桌面输入框内部组件 */
export type DeskTextareaComponents = {
	Box: StyledDIV;
	Textarea: StyledTEXTAREA;
	ErrorReminder: any;
};
/** 桌面日期输入框属性 */
export type DeskDateProps = { format?: string } & DeskTextProps;
/** 桌面日期输入框内部组件 */
export type DeskDateComponents = {
	DisplayLabel: StyledSPAN;
	DisplayLabelCaret: StyledI;
} & DeskTextComponents;
/** 桌面下拉框属性 */
export type DeskSelectProps = {} & ReadonlyProps &
	LabellingComponentProps &
	CheckableWidgetProps &
	CodesWidgetProps;
/** 桌面下拉框内部组件 */
export type DeskSelectComponents = {
	Box: StyledDIV;
	Select: StyledSELECT;
	ErrorReminder: any;
};
/** 桌面勾选框属性 */
export type DeskCheckBoxProps = {} & LabellingComponentProps &
	ModelWidgetProps &
	ReadonlyProps;
/** 桌面勾选框内部组件 */
export type DeskCheckBoxComponents = {
	Box: StyledINPUT;
};
export type DeskCheckBoxSetsProps = {} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps &
	CodesWidgetProps;
export type DeskCheckBoxSetsComponents = {
	Box: StyledINPUT;
	CheckboxSetsContainer: StyledDIV;
	Checkbox: StyledINPUT;
	CheckboxLabel: StyledSPAN;
}
/** 桌面单选框属性 */
export type DeskRadioProps = {
	verticalOrientation?: boolean
} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps &
	CodesWidgetProps;
/** 桌面单选框内部组件 */
export type DeskRadioComponents = {
	RadioContainer: StyledDIV;
	RadioVerticalContainer: StyledDIV;
	Box: StyledINPUT;
	Radio: StyledINPUT;
	RadioLabel: StyledSPAN;
	Desc: StyledSPAN;
};
/** 桌面开关按钮属性 */
export type DeskToggleProps = {} & LabellingComponentProps &
	ModelWidgetProps &
	WidgetProps &
	ReadonlyProps;
/** 桌面开关按钮内部组件 */
export type DeskToggleComponents = {
	Box: StyledDIV;
	InnerBox: StyledDIV;
	Slider: StyledSPAN;
};
/** 桌面计数器属性 */
export type DeskToggleNumberProps = { maxLength?: number } & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
export type DeskToggleNumberComponents = {
	Box: StyledDIV;
	Text: StyledINPUT;
	Button: StyledDIV;
};
/** 桌面树状图属性 */
export type DeskTreeProps = { tree: TreeModel[] } & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
export type DeskTreeComponents = {
	Box: StyledDIV;
	Leaf: StyledDIV;
	SubLeaf: StyledDIV;
};
/** 桌面文件选择器属性 */
export type DeskFilePickerProps = { accept?: string, oss?: boolean, desc?: string, placeholder?: string;fileDirectory?: string; } & DeskTextProps;
export type DeskFilePickerState = {
	fileName?: string;
	ossFileName?: string;
} & CheckableWidgetState;
/** 桌面文件选择器内部组件 */
export type DeskFilePickerComponents = {
	PlaceHolder: StyledSPAN;
	OssPlaceHolder: StyledDIV;
	OssDesc: StyledDIV;
} & DeskTextComponents;
export type DeskImagePickerProps = { url: string } & DeskTextProps;
/** 桌面表格属性 */
export type DeskSpreadSheetProps = {
	/** 无数据显示CSS */
	noDataCss?: (props?: any) => string;
	/** 行显示CSS */
	rowCss?: (props?: any) => string;
} & WidgetProps;
/** 桌面表格内部组件 */
export type DeskSpreadSheetComponents = {
	Box: StyledDIV;
};
/** 桌面表格头内部组件 */
export type DeskSpreadSheetHeaderComponents = {
	Box: StyledDIV;
};
/** 桌面表格单元格属性 */
export type DeskSpreadSheetCellProps = {} & GridCellProps & ModelWidgetProps;
/** 桌面表格单元格内部组件 */
export type DeskSpreadSheetCellComponents = { Box: StyledDIV };
/** 桌面表格行集合属性 */
export type DeskSpreadSheetBodyRowsProps = {
	/** 是否描画无数据, 只有false才会关闭 */
	nodata?: { label?: boolean };
} & ModelWidgetProps;
/** 桌面表格行属性 */
export type DeskSpreadSheetBodyRowProps = {} & GridContainerProps;
/** 桌面表格行内部组件 */
export type DeskSpreadSheetBodyRowComponents = {
	Box: StyledDIV;
};
export type DeskSpreadSheetFooterProps = {};
export type DeskSpreadSheetFooterComponents = { Box: StyledDIV };
/** 桌面表格无数据属性 */
export type DeskSpeadSheetNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
/** 桌面表格无数据内部组件 */
export type DeskSpeadSheetNoDataComponents = {
	Box: StyledDIV;
};
/** 翻页对象 */
export type Pagination = {
	/** 从0开始计数 */
	pageNumber: number;
	pageCount: number;
	pageSize: number;
	totalCount?: number;
	itemCount?: number;
};
/** 翻页变动事件 */
export type PaginationChangeEvent = Pagination & {
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type DeskPaginationProps = {
	onPageChanged?: (evt: PaginationChangeEvent) => void;
} & ModelWidgetProps;
/** 桌面分页内部组件 */
export type DeskPaginationComponents = {
	Box: StyledDIV;
	Arrow: any;
	Button: StyledBUTTON;
	CurrentButton: StyledBUTTON;
	TotalCount: StyledDIV;
	Select?: StyledSELECT;
	Input?: StyledINPUT;
};
// 桌面组件 *******************************************************
export type OtherSmallImageCreator = (url: string) => any;
export type OtherSmallImageArrayOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	item: any;
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type OtherSmallImageArrayOperator = (
	options: OtherSmallImageArrayOptions
) => void;
// 组件 ********************************************

// 系统健康 ********************************************
export type ActuatorResultProps = {
	data?: any | null;
} & WidgetProps;
export type ActuatorResultComponents = {
	Box: StyledDIV;
};
export type ActuatorBodyState = {
	data?: any | null;
};
export type LogDownloadComponents = {
	ContentBox: StyledDIV;
} & DeskPageBodyComponents;
export type LogLevelComponents = {
	ContentBox: StyledDIV;
	Row: StyledDIV;
	Category: StyledINPUT;
	Level: StyledSELECT;
	Confirm: StyledBUTTON;
} & DeskPageBodyComponents;
// 系统健康 ********************************************

// 登录 ********************************************
export type SignCommonPageComponents = {
	Background: StyledDIV;
	TopBlank: StyledDIV;
	Form: StyledDIV &
	React.ComponentClass<{
		pathname?: string;
	}>;
	FormTop: StyledDIV;
	FormSwitchSignin: StyledDIV;
	FormContent: StyledDIV;
	InputRow: StyledDIV &
	React.ComponentClass<{
		focus?: boolean;
	}>;
	Icon: StyledI & React.ComponentClass<FaIconProps>;
	Label: StyledDIV;
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	Select: StyledSELECT;
	Row: StyledDIV;
	BtnBar: StyledDIV;
	SignBtn: StyledBUTTON;
	BackBtn: StyledBUTTON;
	Link: StyledBUTTON;
	RegisterRow: StyledDIV;
	ErrorRow: StyledDIV;
	Button: StyledBUTTON;
	InputValidationCode: StyledINPUT & React.ComponentClass<InputProps>;
} & PageComponents;

export interface SignCommonPageProps extends StyledProps {
	focus?: boolean;
	pathname?: string;
}

export type SignCommonPageState = {
	focus?: React.RefObject<HTMLDivElement>;
	formTitle: string;
	errorMsg?: string;
	step?: number;
	hint?: string;
	accountName?: string;
	accountPassword?: string;
	validateClicked?: boolean;
	passwordModel?: any;
	auth?: string;
	agree?: boolean;
};

// 登录 ********************************************

export enum SignatureServerSideDocType {
	CLIENT_NOTICE = '1',
	CONSIGNMENT_AGREEMENT = '2',
	RECEIPT = '3',
	INSURANCE_CUSTOMER_NOTIFICATION = '4',
	ELECTRONIC_INSURANCE_FORM = '5'
}

// 保单模型 ********************************************
export enum Gender {
	MALE = 'M',
	FEMALE = 'F'
}

export enum ParticipantIdType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	/** 军官退休证 */
	RETIRE_MILITARY_ID = 19,
	/** 其他证件 */
	OTHERS = 99
}

export type ParticipantDeclaration = {
	code?: string;
	answer?: boolean;
	remark?: string;
};

export enum PolicyDocumentType {
	/** 身份证 */
	ID_CARD = 1,
	/** 户口本 */
	FAMILY_REGISTER = 2,
	/** 出生证明 */
	BIRTH_CERTIFICATE = 3,
	/** 驾照 */
	DRIVE_LICENSE = 4,
	/** 军官证 */
	MILITARY_ID = 5,
	/** 护照 */
	PASSPORT = 6,
	/** 港澳通行证 */
	HK_PASS = 7,
	/** 台湾通行证 */
	TW_PASS = 8,
	/** 外国护照 */
	FOREIGN_PASSPORT = 9,
	/** 居住证 */
	FOREIGN_REGISTER_CARD = 10,
	/** 港澳居民来往内地通行证 */
	HK_MAINLAND = 11,
	/** 台湾居民来往大陆通行证 */
	TW_MAINLAND = 12,
	/** 香港/澳门居民居住证 */
	HK_RESIDENT_PERMIT = 13,
	/** 台湾居民居住证 */
	TW_RESIDENT_PERMIT = 14,
	/** 外国人永久居留身份证 */
	PERMANENT_RESIDENT_PERMIT = 15,
	/** 警官证 */
	POLICE_OFFICER_CERTIFICATE = 16,
	/** 临时身份证 */
	TEMPORARY_ID_CARD = 17,
	/** 士兵证 */
	SOLDIER_ID_CARD = 18,
	OTHERS = 99,

	BUSINESS_PROPOSAL_SIGN_HOLDER = '-10', //业务投保单投保人签名PDF
	BUSINESS_PROPOSAL_SIGN_INSURED = '-11', //业务投保单投被保人签名PDF

	/** 客户告知书签名 */
	CLIENT_NOTICE_SIGNATURE = '-129',
	CLIENT_NOTICE_SIGNATURE_IMAGE = '-130',
	CLIENT_NOTICE_SIGNATURE_PDF = '-132',
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	CONSIGNMENT_AGREEMENT_SIGNATURE_PDF = '-137',
	/** 签名 */
	BANK_CARD = '-1',
	SIGNATURE = '-2',
	/** 回执 */
	RECEIPT_SIGNATURE = '-89',
	/** 人脸识别 */
	FACE_RECOGNIZE = '-601',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139',
	/**免除保险人责任条款说明书*/
	WAIVER_INSURER_LIABILLITY_SIGNATURE = '-140'
}

export type PolicyDocument = {
	documentType: PolicyDocumentType;
	front?: boolean;
	url?: string;
	signedDocId?: string | number;
	isComplete?: boolean;
};
export type SignatureDocument = {
	isComplete?: boolean;
	image?: string;
	signDate?: string;
	signImage?: string;
	signComplete?: boolean;
	given?: {};
};

export enum SignatureStatus {
	/** 待签名 */
	PRE_SIGN = '1',
	/** 获取验证码 */
	SEND_CODE = '2',
	/** 已短信验证 */
	VALIDATION = '3',
	/** 已签名 */
	SIGNED = '4',
	/** 重签 */
	RE_SIGN = '5'
}

export type ParticipantSignatureDocument = {
	documentType:
	PolicyDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE
	| PolicyDocumentType.BUSINESS_PROPOSAL_SIGN_HOLDER
	| PolicyDocumentType.BUSINESS_PROPOSAL_SIGN_INSURED
	| PolicyDocumentType.RECEIPT_SIGNATURE
	| PolicyDocumentType.CLIENT_NOTICE_SIGNATURE
	| PolicyDocumentType.SIGNATURE;
} & SignatureDocument;
export type ParticipantSignatureCADocument = {
	documentType:
	| PolicyDocumentType.CLIENT_NOTICE_SIGNATURE_PDF
	| PolicyDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_PDF;
	signedDocId?: string | number;
	ossLocation?: string;
	url?: string;
};
export type Participant = {
	/** 名. 中文为全名 */
	firstName?: string;
	/** 姓 */
	lastName?: string;
	/** 全名 */
	fullName?: string;
	/** 性别 */
	gender?: Gender;
	/** 出生日期 */
	dateOfBirth?: string;
	/** 证件类型 */
	idType?: ParticipantIdType | string;
	/** 证件号码 */
	idNo?: string;
	/** 移动电话 */
	mobile?: string;
	/** 邮件地址 */
	email?: string;
	/** 省份代码 */
	provinceCode?: string;
	/** 城市代码 */
	cityCode?: string;
	/** 区县代码 */
	regionCode?: string;
	/** 地址1 */
	addressLine1?: string;
	/** 邮政编码 */
	postCode?: string;
	extensionData?: {
		age?: number;
		/** 工作单位 */
		workUnit?: string;
		/** 年收入(元) */
		annualIncome?: string | number;
		/** 收入来源 */
		incomeSource?: string | number;
		/** 告知 */
		declarations?: ParticipantDeclaration[];
		/** 上传文档 */
		documents?: (
			| PolicyDocument
			| ParticipantSignatureDocument
			| ParticipantSignatureCADocument)[];
		signature?: {
			signatureId?: string | number;
			signatureStatus?: SignatureStatus;
			url?: string;
		};
		/** 一字多签被保人签名是否完成 */
		signComplete?: boolean;
		/** 被保人签名base64 */
		signImage?: string;
		/** 被保人签名日期 */
		signDate?: string;
		/** 国籍*/
		nationality?: string;
		/** 职业类别code*/
		jobCateCode?: string;
		/**与投保人关系 */
		relationWithPH?: string;
		/** 居民税收身份证明 */
		taxDweller?: TaxDweller;
		/** 身高 */
		height?: string;
		/**体重 */
		weight?: string;
		[propName: string]: any;
	};
};
export type TaxDweller = {
	taxType?: string;
}

export enum PolicyCustomerType {
	/** 投保人 */
	HOLDER = 1,
	/** 被保人 */
	INSURED = 2,
	/** 受益人 */
	BENEFICIARY = 3
}

export type CoverageFeeModel = {
	app?: string;
}

export type InsuredParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.INSURED;
	extensionData?: {
		partySerialId?: string | number;
		/** 与投保人关系 */
		relationWithPH?: RelationTypeWith;
		signatureEnabled?: boolean;
		csModel?: CSModel;
	};
} & Participant;

export enum BeneficiaryType {
	/** 死亡受益人 */
	DEATH = '1',
	/** 祝寿金受益人 */
	BIRTH_CONGRATULATE = '4'
}

export type BeneficiaryParticipant = {
	/** 序列号 */
	insuredParticipantId?: number;
	/** 参与者类型 */
	customerType: PolicyCustomerType.BENEFICIARY;
	extensionData?: {
		partySerialId?: string | number;
		/** 受益类型 */
		beneficiaryType?: BeneficiaryType;
		/** 是否法定受益人 */
		legalIndi?: 'Y' | 'N' | null;
		sameWithPH?: 'Y' | 'N' | null;
		/** 受益顺序 */
		benefitOrder?: number;
		/** 受益比例 */
		benefitRatio?: number;
	};
} & Participant;
export type CoverageModel = {
	/** 险种代码 */
	coverageCode?: string;
	/** 险种名称 */
	coverageName?: string;
	/** 险种类型, R: 附加险, M: 主险 */
	coverageType?: 'R' | 'M';
	coverageFee?: CoverageFeeModel;
	extensionData?: {
		/** 险种类型, R: 附加险, M: 主险 */
		coverageType?: 'R' | 'M';
		/** 缴费期间 */
		installment?: string;
		/** 交费频率 */
		paymentFreq?: string;
		/**档次 */
		benefitLevel?: string;
		sumInsured?: string | number;
		/** 险种是否被选择 */
		enabled?: boolean;
		/**保障期限 */
		coveragePeriod?: string;
		isWaiver?: string;
		autoRenewalIndi?: 'Y' | 'N' | null;
		/**险种责任code */
		responsibility?: string;
		//责任
		liabilityList?: LiabilityModel[];
		//外部保司code
		externalCode?: string;
	};
};

export type LiabilityModel = {
	liabilityCode: string;
	selected: 'Y' | 'N' | true | false | null;
	value: 'Y' | 'N' | true | false | null;
}
export type InsuredModel = {
	insuredId?: string | number;
	/** 标的名称, 可以是人名, 牌照等等 */
	insuredName?: string;
	/** 参与方 */
	participants?: (InsuredParticipant | BeneficiaryParticipant)[];
	/** 险种 */
	coverages?: CoverageModel[];
	extensionData?: {
		/** 是否是主标的 */
		mainInsured?: 'Y' | 'N';
		/** 是否是法定受益人. N:法定; Y:指定 */
		hasDesignatedBeneficiary?: 'Y' | 'N';
		/** 显示顺序 */
		displayOrder: number;
	};
	[propName: string]: any;
};
// 树模型
export type TreeModel = {
	// 叶子ID
	key?: string
	// 叶子名称
	title?: string;
	// 子节点
	children?: TreeModel[]
};

// export enum CLPCPPolicyStatus {
// 	WAIT_FOR_SUBMIT = '11',
// 	QUOTE_SUBMITTED = '51',
// 	AUTO_UW_PASS = '106',
// }
export enum PolicyDetailStatus {
	DELETE_CATEGORY = "0",// "删除"),
	DELETE = "1", //"删除", "0"),
	WAIT_FOR_SUBMIT_CATEGORY = "10", //"待提交"),
	WAIT_FOR_SUBMIT = "11", //"待提交", "10"),
	IN_QUOTATION = "12", //"报价中", "10"),
	SUBMITTED_CATEGORY = "50", //"投保提交"),
	SUBMITTED = "51", //"投保提交", "100"),
	SUBMITTED_FAILED = "52", //"投保提交失败"),
	QUOTE_CANCEL_AUTO = "81",//"自动撤单", "100"),
	QUOTED_CATEGORY = "100", //"承保中"),
	QUOTE_MANUAL_UW = "101", //"人工核保中", "100"),
	QUOTE_FAILED = "102", //"投保失败", "100"),
	QUOTE_CANCEL = "103", //"撤单", "100"),
	QUOTE_TRANSFERRING = "104", //"转账收费中", "100"),
	QUOTE_TRANSFER_FAILED = "105",// "转账失败", "100"),
	QUOTE_AUTO_UW_PASS = "106", //"自核通过", "100"),
	QUOTE_UW_PASS = "107", //"核保通过", "100"),
	QUOTE_TRANSFER_SUCCESS = "108",// "转账成功", "100"),
	QUOTE_OTHER = "109", //"其他", "100"),
	QUOTE_UW_1 = "110", //"核保中", "100"),
	QUOTE_UW_2 = "111", //"核保中", "100"),
	QUOTE_AUTO_UW_FAILED = "112", //"自核失败", "100"),
	WAITING_FOR_COLLECTION = "114", //"待支付"),
	DECLINED = "115",// "拒保", "100"),
	QUOTE_REFUNDING = "120",// "退费中", "100"),
	QUOTE_REFUND_SUCCESS = "121",// "退费成功", "100"),
	QUOTE_REFUND_FAILED = "122", //"退费失败", "100"),
	COLLECTION_APPLY_PENDING = "123",// "支付申请中", "100"),
	ISSUED_CATEGORY = "200", //"承保"),
	ISSUED = "201", //"已承保", "200"),
	ISSUED_RECEIPT_RETURNED = "202",// "已回执", "200"),
	ISSUED_RECEIPT_AUDITING = "203", //"回执审核中", "200"),
	ISSUED_RECEIPT_DONE = "204", //"已回访", "200");
	INVALID_DUE_TO_DIFFERENCE = "151"//已失效（差异单）
}

/**
 *  1现金
	2支票
	3银行转账
	4信用卡
	5微信支付
	6线下转账
	7银联网页支付
	999其他
 */
export enum PaymentMethod {
	BATCH_OFFLINE = '6',
	REAL_TIME = '3',
	CASH = '1',
	CHEQUE = '2',
	CREDIT_CARD = '4',
	WECHAT_PAY = '5',
	UNION_WEB_PAY = '7',
	OTHER = '999'
}

export enum PayType {
	REAL_TIME_DEDUCTION = '1',//实时扣款
	BANK_APPROVED_DEDUCTION = '2'//银行批扣
}

/**
 * 签名方式
 */
export enum SignatureMethod {
	LOCAL = '1',
	SHARE = '2'
}

export enum AgentDocumentType {
	/** 保险经纪服务委托合同签名 */
	CONSIGNMENT_AGREEMENT_SIGNATURE = '-134',
	CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE = '-135',
	/**人身保险投保提示书*/
	INSURANCE_NOTICE_SIGNATURE = '-139'
}

export type CaPositionKeywordModel = {
	keyword: string;
	offsetX: number;
	offsetY: number;
	page: number;
	times: number;
}

export type CaPositionModel = {
	height: number;
	keyword: CaPositionKeywordModel,
	page: number;
	positionType: string;
	width: number;
}

export type CaCommandModel = {
	imageUrl?: string;//传oss地址
	base64Image: string;
	email: string;
	idNo: string;
	idNoType: string;
	mobile: string;
	positions: CaPositionModel[],
	realName: string;
}

export type AgentDocument = {
	documentType:
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE
	| AgentDocumentType.CONSIGNMENT_AGREEMENT_SIGNATURE_IMAGE
	| AgentDocumentType.INSURANCE_NOTICE_SIGNATURE;
	url?: string;
} & SignatureDocument;

export type PaymentInfo = {
	/**付款方式 */
	paymentMethod?: PaymentMethod;
	/** 银行code */
	bankCode?: string;
	/** 银行卡号 */
	bankCard?: string;
}

export type PolicyModel = {
	policyId?: number;
	productCode?: string;
	quotationNo?: string;
	policyNo?: string;
	insureds?: InsuredModel[];
	policyHolder?: Participant & {
		extensionData?: {
			receiptSignature?: { signatureId?: number | string };
		};
	};
	extensionData?: {
		/** CLPCP明细状态 */
		status?: PolicyDetailStatus;
		/** 当前UI步骤名称 */
		step?: string;
		documents?: {
			documents?: AgentDocument[];
		};
		rootUrlFrom?: string;
		urlFrom?: string;
		/** 当前正在签名的序列号 */
		signatureId?: string | number;
		/** 验证码 */
		validateCode?: string;
		receiptMethod?: ReceiptMethod;
		receipt?: string;
		/** IMPORTANT 临时图片, 用于显示某张图片用, 不持久化, 用完需要被删掉 */
		tempImage?: string;
		/** IMPORTANT 临时年龄, 用于显示文案信息判断, 不持久化, 用完需要被删掉 */
		tempAge?: string;
		/** 保单扩展字段是否双录字段 */
		needDoubleRecording?: boolean;
		/** 根供应商 */
		rootVendorCode?: string;
		/**支付信息 */
		paymentInfo?: PaymentInfo;
		/**保全模型 */
		csModel?: CSModel;
		/**保全action */
		csAction?: CsAction;
		/**临时 */
		tempInsuredId?: number;
		/**临时 */
		tempInsuredParticipantId?: number;
		//微信小程序openid
		openid?: string;

	};
	[propName: string]: any;
};

//回执方式
export enum ReceiptMethod {
	LOCAL = '1',
	SHARE = '2'
};

export enum FaceRecongnizeRoleType {
	POLICY_HOLDER = 'PROPOSER',
	POLICY_INSURED = 'INSURED',
	POLICY_BENEFICIARY = 'BENEFICIARY'
};

// 保单模型 ********************************************


export enum CsAction {
	//变更银行卡信息
	CHANGE_BANK_INFO = 'changeBankInfo',
}

//保全时的模型
export type CSModel = {
	documents?: (
		| PolicyDocument
		| ParticipantSignatureDocument
		| ParticipantSignatureCADocument)[];
	[propName: string]: any;
}

// 面客平台登录 ********************************************
export type SignCommonPageComponents2 = {
	FormBox: StyledDIV & React.ComponentClass<{
		autoComplete?: string;
	}>,
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	Row: StyledDIV & React.ComponentClass<StyledPropsCss & {
		align?: string;
	}>;
	Link: StyledBUTTON & React.ComponentClass<ButtonProps & {
		className?: string;
		color?: string;
	}> & React.ComponentClass<Clickable>;
	Button: StyledBUTTON & React.ComponentClass<ButtonProps & {
		className?: string;
	}> & React.ComponentClass<Clickable>;
	Error: StyledDIV & React.ComponentClass<StyledPropsCss>;
	CheckBox: StyledINPUT;
}
export type PersonalCommonPageComponents = {
	Title: StyledDIV & React.ComponentClass<StyledPropsCss>;
	ButtonRow: StyledDIV & React.ComponentClass<StyledPropsCss>;
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	Row: StyledDIV & React.ComponentClass<StyledPropsCss>;
	Error: StyledDIV & React.ComponentClass<StyledPropsCss>;
	Tab: StyledDIV & React.ComponentClass<StyledPropsCss>;
}